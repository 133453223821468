<template>
    <v-container>
        <PurchaseStatus />
        <v-row
            class="d-flex flex-wrap-reverse my-10 mx-5"
            align-content="start"
        >
            <v-col cols="12" md="5">
                <v-flex class="mb-5">
                    <h1>{{ courseObject.courseName }}</h1>
                </v-flex>
                <v-flex class="mb-5">
                    <v-row no-gutters>
                        <v-avatar size="56" cover>
                            <v-img
                                :src="courseObject.instructor.image"
                                :alt="courseObject.instructor.image"
                            />
                        </v-avatar>
                        <div class="profile-name">
                            {{ courseObject.instructor.name }}
                        </div>
                    </v-row>
                </v-flex>
                <DescriptionText :content="courseObject.description" />
                <v-flex>
                    <h1 class="py-5 font-weight-medium">{{ price }}</h1>
                </v-flex>
                <v-flex>
                    <h3 class="pt-5 font-weight-regular">
                        {{ numberSessions }}
                    </h3>
                </v-flex>
                <v-flex>
                    <h4 class="font-weight-regular">
                        {{ firstSession }} to {{ lastSession }}
                    </h4>
                </v-flex>
                <v-flex class="d-flex flex-row flex-wrap">
                    <div
                        v-for="(amenity, index) in location.amenities"
                        :key="index"
                        class="icon-background mt-5 px-2"
                    >
                        <img
                            :src="getImgUrl(amenity)"
                            :alt="amentityDict[amenity]"
                            class="icon-img"
                        />
                        <p class="icon-text">{{ amenity }}</p>
                    </div>
                </v-flex>
                <v-flex>
                    {{ locationDescription }}
                </v-flex>
            </v-col>
            <v-col cols="12" md="7">
                <v-img
                    :aspect-ratio="16 / 9"
                    :src="courseObject.images[0]"
                    :alt="courseObject.courseName"
                    class="course-img"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { dateStringFromTimestamp } from '@/util/dateformat.js'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { mapGetters } from 'vuex'
import PurchaseStatus from '@/components/stripe/PurchaseStatus'
import DescriptionText from '@/components/shared/DescriptionText'

export default {
    props: {
        courseObject: Object,
    },
    components: {
        PurchaseStatus,
        DescriptionText,
    },
    data() {
        return {
            images: import.meta.glob('@/assets/icons/*.svg', {
                eager: true,
            }),
            amentityDict: {
                Shower: `shower`,
                Showers: `shower`,
                Locker: `locker`,
                Lockers: `locker`,
                Mat: `mat`,
                Parking: `parking`,
                Towel: `towel`,
            },
        }
    },
    computed: {
        price() {
            const currency = this.studio.currency ?? 'SGD'
            const price = this.courseObject.price
            return `${currency} ${price}`
        },
        firstSession() {
            const first = dateStringFromTimestamp(
                this.courseObject.firstStartTimestamp.toDate(),
                this.studio.timezone
            )
            return first
        },
        lastSession() {
            const last = dateStringFromTimestamp(
                this.courseObject.lastEndTimestamp.toDate(),
                this.studio.timezone
            )
            return last
        },
        numberSessions() {
            return `${this.courseObject.timings.length} sessions`
        },
        locationDescription() {
            const location = this.location
            return `${location.name}, ${location.address} ${location.postalCode}`
        },
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        locations() {
            const locations = this.studio.locations
            const locationObject = {}
            for (const location of locations) {
                const name = location.name
                locationObject[name] = location
            }
            return locationObject
        },
        location() {
            const locationName = this.courseObject.location
            return this.locations[locationName]
        },
    },
    methods: {
        getImgUrl(amenity) {
            const path = Object.keys(this.images).find((p) =>
                p.endsWith(`/${this.amentityDict[amenity]}.svg`)
            )
            return path ? this.images[path].default : '' // Return image URL
        },
    },
}
</script>
<style scoped>
.course-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.icon-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 10px;
    margin-right: 10px;
    max-width: 60px;
    padding-top: 20px;
    max-height: 60px;
    border-radius: 4px;
}

.icon-img {
    display: flex;
    width: 40px;
    height: 40px;

    @media screen and (max-width: 600px) {
        width: 24px;
        height: 24px;
    }
}

.profile-name {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 15px;
}

.icon-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
</style>
