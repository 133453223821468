import { functions, httpsCallable } from '@/firebase'

/**
 * Gets supported countries.
 */
function getSupportedCountries() {
    const getAllCountries = httpsCallable(functions, 'getAllCountries')
    return getAllCountries().then((result) => result.data)
}

const countries = [
    {
        code: 'HK',
        name: 'Hong Kong',
    },
    {
        name: 'Indonesia',
        code: 'ID',
    },
    {
        code: 'MY',
        name: 'Malaysia',
    },
    {
        code: 'PH',
        name: 'Philippines',
    },
    {
        code: 'SG',
        name: 'Singapore',
    },
    {
        code: 'TH',
        name: 'Thailand',
    },
    {
        code: 'VN',
        name: 'Vietnam',
    },
]

export function getCountryNameByCode(code) {
    return countries.find((country) => country.code === code)?.name ?? ''
}

export default getSupportedCountries
