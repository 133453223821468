import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-composable'
import en from './locales/en.json'
import zh from './locales/zh.json'
import zh_hant from './locales/zh_hant.json'
import vn from './locales/vn.json'
Vue.use(VueI18n)

// function loadLocaleMessages() {
//   const locales = require.context(
//     './locales',
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );

//   const messages = {};

//   locales.keys().forEach((key) => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });

//   return messages;
// }

const messages = {
    en,
    zh,
    zh_hant,
    vn,
}

const i18n = new createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})

export default i18n
