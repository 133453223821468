<template>
    <div>
        <Banner />
        <v-row :class="rowStyle">
            <v-col>
                <v-tabs v-model="tabs" :centered="!isWidget">
                    <v-tab>{{ $t('message.classes') }}</v-tab>
                    <v-tab>{{ $t('message.courses') }}</v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="4" v-if="isWidget">
                <UserAuth />
            </v-col>
        </v-row>

        <v-tabs-items v-model="tabs" class="my-5">
            <v-tab-item>
                <Classes class="mx-sm-5" />
            </v-tab-item>
            <v-tab-item>
                <Courses class="mx-sm-5" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import Classes from './Classes'
import Courses from './Courses'
import UserAuth from '@/components/auth/UserAuth'
import { WIDGET_ROUTE } from '@/constants'
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'

import Banner from '@/components/announcements/Banner.vue'

export default {
    components: {
        Classes,
        Courses,
        UserAuth,
        Banner,
    },
    data() {
        return {
            tabs: null,
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        isWidget() {
            return this.$route.hash === WIDGET_ROUTE
        },
        rowStyle() {
            return this.isWidget ? 'px-5 pt-3' : ''
        },
        isKiYoga() {
            return this.studio.id === 'XuDwm2BT6oMjIJ0FOZyd'
        },
        isMobile() {
            return this.$vuetify.breakpoint.xsOnly
        },
    },
    methods: {
        setDefaultTab() {
            this.tabs = this.studio.webAppTab == 'courses' ? 1 : 0
        },
    },
    created() {
        this.setDefaultTab()
    },
}
</script>
