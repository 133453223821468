<template>
    <v-row class="d-flex flex-wrap-reverse my-10 mx-5" align-content="start">
        <v-col cols="12" md="5">
            <v-flex class="mb-5">
                <h1>{{ this.classObject.name }}</h1>
            </v-flex>
            <v-flex class="mb-5">
                <v-row
                    v-for="instructor in this.classObject.instructors"
                    :key="instructor.userId"
                    no-gutters
                    class="pb-2"
                >
                    <v-avatar size="56" cover>
                        <v-img
                            :src="instructor.image"
                            :alt="instructor.image"
                        />
                    </v-avatar>
                    <div class="d-flex align-center item-center">
                        <span class="profile-name text-truncate">
                            {{ instructor.name }}
                        </span>
                    </div>
                </v-row>
            </v-flex>

            <DescriptionText :content="classObject.description" />

            <v-flex class="my-5">
                <p class="date-time font-weight-bold mb-0">
                    {{ $t('message.date') }}
                </p>
                {{ dateClass }}
            </v-flex>

            <v-flex class="mb-5">
                <p class="date-time font-weight-bold mb-0">
                    {{ $t('message.time') }}
                </p>
                {{ startTimeClass }} to {{ endTimeClass }}
            </v-flex>
            <v-flex class="d-flex flex-row flex-wrap">
                <div
                    v-for="(amenity, index) in classObject.location.amenities"
                    :key="index"
                    class="icon-background mt-5 px-2"
                >
                    <img
                        :src="getImgUrl(amenity)"
                        :alt="amentityDict[amenity]"
                        class="icon-img"
                    />
                    <p class="icon-text">{{ amenity }}</p>
                </div>
            </v-flex>
            <v-flex>
                {{ locationDescription }}
            </v-flex>
        </v-col>

        <v-col cols="12" md="7">
            <v-carousel
                height="auto"
                v-if="
                    classObject.urls.length > 1 || classObject.images.length > 1
                "
            >
                <div
                    v-for="({ url, type }, index) in classObject.urls"
                    :key="index"
                >
                    <v-carousel-item>
                        <video
                            autoplay
                            controls
                            width="100%"
                            height="100%"
                            v-if="type === 'video'"
                        >
                            <source :src="url" />
                        </video>
                        <v-img
                            v-else
                            :aspect-ratio="16 / 9"
                            :src="url"
                            :alt="classObject.name"
                            class="class-img"
                        />
                    </v-carousel-item>
                </div>
            </v-carousel>
            <v-img
                v-else
                :aspect-ratio="16 / 9"
                :src="classObject.images[0]"
                :alt="classObject.name"
                class="class-img"
            />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import {
    dateStringFromTimestamp,
    timeStringFromTimestamp,
} from '@/util/dateformat.js'
import DescriptionText from '@/components/shared/DescriptionText'

export default {
    components: {
        DescriptionText,
    },
    props: {
        classObject: Object,
    },
    data() {
        return {
            amentityDict: {
                Shower: `shower`,
                Showers: `shower`,
                Locker: `locker`,
                Lockers: `locker`,
                Mat: `mat`,
                Parking: `parking`,
                Towel: `towel`,
            },
            images: import.meta.glob('@/assets/icons/*.svg', {
                eager: true,
            }),
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        classDescription() {
            const original = this.classObject.description
            const split = original.split('\n')
            return split
        },
        locationDescription() {
            const location = this.classObject.location
            return `${location.name}, ${location.address} ${location.postalCode}`
        },
        dateClass() {
            return dateStringFromTimestamp(
                this.classObject.startTimestamp,
                this.studio.timezone
            )
        },
        startTimeClass() {
            return timeStringFromTimestamp(
                this.classObject.startTimestamp,
                this.studio.timezone
            )
        },
        endTimeClass() {
            return timeStringFromTimestamp(
                this.classObject.endTimestamp,
                this.studio.timezone
            )
        },
    },
    methods: {
        getImgUrl(amenity) {
            const path = Object.keys(this.images).find((p) =>
                p.endsWith(`/${this.amentityDict[amenity]}.svg`)
            )
            return path ? this.images[path].default : '' // Return image URL
        },
    },
}
</script>
<style scoped>
.class-img {
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.icon-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 10px;
    margin-right: 10px;
    max-width: 60px;
    padding-top: 20px;
    max-height: 60px;
    border-radius: 4px;
}

.icon-img {
    display: flex;
    width: 40px;
    height: 40px;

    @media screen and (max-width: 600px) {
        width: 24px;
        height: 24px;
    }
}

.icon-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #1a1a1a;
}

.profile-name {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    margin-left: 15px;
    max-width: 18ch;
}
</style>
