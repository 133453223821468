<template>
    <div>
        <div class="d-flex flex-column align-center justify-center">
            <v-img
                :src="creditCardImage"
                max-width="400px"
                min-height="200px"
                class="ma-5"
            />

            <p class="error--text text-center mx-2">{{ error }}</p>

            <div class="d-flex flex-column">
                <v-btn
                    color="primary"
                    @click="openCheckoutSession"
                    class="my-3"
                    data-testid="proceed-button"
                >
                    <v-icon class="mr-2"> mdi-lock </v-icon>
                    {{ $t('message.proceedToSetupPage') }}
                </v-btn>

                <v-btn
                    v-if="hasOpenedCheckoutSession"
                    color="secondary"
                    @click="getAddPaymentMethodStatus"
                    class="my-3"
                    :loading="checkingStatus"
                    data-testid="continue-button"
                >
                    {{ $t('message.continue') }}
                </v-btn>
            </div>

            <p v-if="hasOpenedCheckoutSession" class="text-center">
                {{ $t('message.clickOnContinue') }}
            </p>
        </div>
    </div>
</template>

<script>
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import {
    createCheckoutSessionToAddPaymentMethod,
    retrieveCheckoutSessionStatus,
} from '@/util/cloudFunctions/stripe'
import { mapGetters } from 'vuex'
import creditCardImage from '@/assets/credit-card.png'

export default {
    emits: ['AddPaymentMethodSuccess', 'loadingCheckoutSession'],
    mounted() {
        this.openCheckoutSession()
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    data() {
        return {
            error: '',
            checkoutSessionId: '',
            checkoutSessionUrl: '',
            hasOpenedCheckoutSession: false,
            checkingStatus: false,
            creditCardImage,
        }
    },
    methods: {
        async openCheckoutSession() {
            this.$emit('loadingCheckoutSession', true)
            this.hasOpenedCheckoutSession = false
            this.baseUrl = window.location.origin

            // If the checkout session has not been created yet, create it
            if (this.checkoutSessionUrl === '') {
                const result = await createCheckoutSessionToAddPaymentMethod(
                    this.studio.id,
                    this.baseUrl,
                    'website'
                )

                if (!result.success) {
                    this.error =
                        'An issue occurred while creating the checkout session. Please retry by pressing the button.'
                    this.$emit('loadingCheckoutSession', false)
                    return
                }

                this.checkoutSessionId = result.sessionId
                this.checkoutSessionUrl = result.sessionUrl
            }

            window.open(this.checkoutSessionUrl, '_blank')
            this.hasOpenedCheckoutSession = true
            this.loadingCheckoutSession = false
            this.$emit('loadingCheckoutSession', false)
        },
        async getAddPaymentMethodStatus() {
            this.checkingStatus = true
            const result = await retrieveCheckoutSessionStatus(
                this.checkoutSessionId,
                this.studio.stripeAccountId
            )
            if (!result.success) {
                this.error =
                    'An issue occurred while retrieving the checkout session status. Please try again.'
                this.openCheckoutSession()
                this.checkingStatus = false
                return
            }

            this.checkingStatus = false
            this.$emit('AddPaymentMethodSuccess')
        },
    },
}
</script>
