<template>
    <v-dialog
        v-model="value"
        hide-overlay
        width="500px"
        @click:outside="$emit('input', false)"
    >
        <img :src="qrCodeSvg" />
    </v-dialog>
</template>
<script>
import qrCodeSvg from '@/assets/vibefam-app-qrcode.svg'
export default {
    props: ['value'],
    data() {
        return {
            qrCodeSvg,
        }
    },
}
</script>
