import { functions, httpsCallable } from '@/firebase'

/**
 * Creates User and adds User as Member.
 * @param {String} studioId
 * @param {String} fullName
 * @param {String} email
 * @param {String} username
 * @param {String} dob in ISO format
 * @param {String} mobileNum
 * @param {String} password
 * @param {String} country in Country Code
 * @param {String} userId
 * @returns
 */
function createUserAndAddAsMember(
    studioId,
    fullName,
    email,
    username,
    dob,
    mobileNum,
    password,
    country,
    userId,
    memberCustomData,
    appSource
) {
    const createUserAndAddAsMemberDoc = httpsCallable(
        functions,
        'createUserAndAddAsMember'
    )

    const payload = {
        studioId,
        fullName,
        email,
        username,
        dob: dob ? dob : null,
        mobileNum,
        password,
        country,
        userId,
        memberCustomData,
        appSource,
    }

    return createUserAndAddAsMemberDoc(payload)
        .then((result) => result.data)
        .catch(() => ({
            success: false,
            error: 'Something went wrong. Please contact vibefam at contact@vibefam.com for help.',
        }))
}

export function preSignupValidationFn(email) {
    const preSignupValidation = httpsCallable(functions, 'preSignupValidation')
    return preSignupValidation({ email })
        .then((result) => result.data)
        .catch(() => ({
            success: false,
            error: 'Something went wrong. Please contact vibefam at contact@vibefam.com for help.',
        }))
}

export default createUserAndAddAsMember
