<template>
    <v-card outlined tile class="mt-2" width="110%" height="50px">
        <v-row>
            <v-col cols="3" no-gutters class="tw-pt-2">
                <v-icon large class="ml-3" color="grey lighten-1"
                    >mdi-account-circle-outline</v-icon
                >
            </v-col>

            <div class="tw-mt-1 tw-flex tw-flex-col">
                <div>{{ username }}</div>
                <div>{{ name }}</div>
            </div>

            <v-col class="tw-text-end tw-pr-8">
                <p>{{ numberClassesUsed }}/{{ numberClassesShared }}</p>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
export default {
    props: {
        username: String,
        name: String,
        numberClassesUsed: Number,
        numberClassesShared: Number,
    },
}
</script>
