import { auth } from '@/firebase'

export async function sendContainerRequest(name, payload) {
    try {
        const currentUser = auth.currentUser
        let token
        if (currentUser) {
            try {
                token = await currentUser.getIdToken()
            } catch {
                token = undefined
            }
        }
        const authHeader = token ? { Authorization: `Bearer ${token}` } : {}
        const response = await fetch(
            `${import.meta.env.VITE_CONTAINER_URL}/${name}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-User-Agent': 'VibefamClient',
                    ...authHeader,
                },
                body: JSON.stringify(payload),
            }
        )
        const result = await response.json()
        if (result.success === undefined) {
            return {
                data: {
                    success: false,
                    error: `HTTP error, status: ${response.status}`,
                },
            }
        }
        return {
            data: result,
        }
    } catch (err) {
        return {
            data: {
                success: false,
                error: `Fetch error: ${err}`,
            },
        }
    }
}
