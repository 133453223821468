import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import router from './router'
import './styles/globals.scss'
import '@/assets/global.scss'
import { store } from './store'
import VueGtag from 'vue-gtag'
import Hotjar from 'vue-hotjar'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import './validationRules'
import VueMeta from 'vue-meta'
import './style.css'
import { createPinia, PiniaVuePlugin } from 'pinia'
import i18n from './i18n'
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.config.productionTip = false
Vue.use(VueGtag, {
    config: { id: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID },
})

Vue.use(router)
Vue.use(Hotjar, {
    id: import.meta.env.VITE_HOTJAR_SITE_ID,
    isProduction: import.meta.env.VITE_SERVER === 'production',
    snippetVersion: 6,
})
Vue.use(VueMeta)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
    vuetify,
    store,
    router,
    i18n,
    pinia,
    render: (h) => h(App),
}).$mount('#app')
